<template>
  <div class="flex_column">
    <dog-table
      ref="dogTable"
      id="685f6659-4fbf-4a78-ba65-2fe6a8ee6938"
      :columns="tableColumns"
      :service="getList"
      :has-paging="false"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-dictionary-item-dialog
      ref="addDictionaryItemDialog"
      @confirm="searchData"
    ></add-dictionary-item-dialog>
  </div>
</template>

<script>
  import dictionaryService from '@/api/service/dictionary';
  import addDictionaryItemDialog from './addDictionaryItemDialog.vue';

  export default {
    name: 'dictionaryManage__rightPart',
    components: { addDictionaryItemDialog },
    data() {
      return {
        currentDictionary: {},
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 60,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <permission-element
                config={[
                  {
                    code: 'editDictionaryItem',
                    callback: () =>
                      this.$refs.addDictionaryItemDialog.openFrame({
                        id: row.id,
                        dictionaryValue: this.currentDictionary.dictionaryValue
                      })
                  }
                ]}></permission-element>
            )
          },
          { prop: 'dictionaryItemName', label: '字典项名称', width: 100 },
          { prop: 'dictionaryItemValue', label: '字典项值', width: 100 },
          {
            prop: 'dictionaryItemEnable_bn',
            label: '是否启用',
            width: 80,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.dictionaryItemEnable_bn}
                onInput={(val) => this.quickOperate(row.id, val)}></dog-switch>
            )
          },
          {
            prop: 'dictionaryItemSort',
            label: '排序',
            width: 50,
            widthGrow: false
          }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addDictionaryItem',
            callback: () =>
              this.$refs.addDictionaryItemDialog.openFrame({
                dictionaryValue: this.currentDictionary.dictionaryValue
              })
          },
          {
            buttonType: 'danger',
            code: 'deleteDictionaryItem',
            callback: this.deleteDictionaryItems
          }
        ],
        selectedData: []
      };
    },
    methods: {
      getData(dictionary) {
        this.currentDictionary = dictionary;
        this.searchData();
      },
      searchData() {
        this.$refs.dogTable.search();
      },
      getList(params) {
        params.dictionaryValue = this.currentDictionary.dictionaryValue;
        return dictionaryService.getDictionaryItemList(params);
      },
      quickOperate(id, val) {
        const transVal = val ? '1' : '0';
        this.$confirm(`是否确定${val ? '启用' : '禁用'}所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          dictionaryService
            .quickOperateDictionaryItem({ id, dictionaryItemEnable: transVal })
            .then(this.searchData);
        });
      },
      deleteDictionaryItems() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          dictionaryService
            .deleteDictionaryItems({
              ids: this.selectedData.map((item) => item.id)
            })
            .then(this.searchData);
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
