<template>
  <div class="flex_row dictionaryManage">
    <left-part
      class="flex_column dictionaryManage__left"
      @select-dictionary="selectDictionary"
    ></left-part>
    <el-divider
      direction="vertical"
      class="dictionaryManage__divider"
    ></el-divider>
    <right-part
      ref="rightPart"
      class="flex_1 flex_column dictionaryManage__right"
    ></right-part>
  </div>
</template>

<script>
  import leftPart from './leftPart.vue';
  import rightPart from './rightPart.vue';

  export default {
    name: 'dictionaryManage',
    components: { leftPart, rightPart },
    data() {
      return {};
    },
    methods: {
      selectDictionary(dictionary) {
        this.$refs.rightPart.getData(dictionary);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(dictionaryManage) {
    height: 100%;
    @include e(left) {
      width: 500px;
    }
    @include e(divider) {
      height: 100%;
    }
  }
</style>
